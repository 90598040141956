<template>
	<div class="sharecard" :class="{ 'show': openShare }">
		<div class="sharecard__box">
			<span class="sharecard__close" @click="closeRequest"></span>
			<span class="sharecard__box__content">
				<span class="sharecard__box__content__title">
					Condividi la tua Digital Business Card
				</span>
			</span>
			<figure v-if="code" class="sharecard__media o-ratio u-1:1">
				<img :src="this.$api.getQrCode(this.code)" alt=""
					 class="sharecard__media__image"/>
			</figure>
		</div>
	</div>
</template>

<script>
import {EventBus} from '@/events_bus.js';

export default {
	name: 'ShareCard',
	props: {
		code: {
			type: String,
			required: true,
		}
	},
	data() {
		return {
			openShare: false,
			qr: null,
		}
	},
	methods: {
		validEmail(email) {
			var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			return re.test(email);
		},
		closeRequest() {
			EventBus.$emit('close-share');
			this.openShare = false;
		},
		sendRequest() {
			if (this.form && this.form.email) {
				let isValid = this.validEmail(this.form.email);
				if (isValid) {
					this.emailerror = false;
					this.$service.login.login(this.code, this.form.email).then(res => {
						console.log('RES', res);
						this.closeConfirmEmail();
						this.$router.push({name: 'confirmed.page', query: {email: this.form.email}});
					}).catch(err => {
						if (err) {
							switch (err.status) {
								case 403:
									this.errmsg = 'La mail inserita non è corretta';
									break;
							}
						}
					})
				} else {
					this.emailerror = true;
				}
			}
		},
		init() {
			if (this.$route && this.$route.query && this.$route.query.id) {
				this.code = this.$route.query.id;
				this.openShare = true;
			}
		}
	},
	mounted() {
		this.init();
	}
}
</script>

<style lang="scss">
.sharecard {
	position: fixed;
	top: 0;
	left: 0;
	background: rgba($color-black, 0.9);
	@extend %flexCenterCenter;
	@include size(100%, 100svh);
	z-index: 100000;
	opacity: 0;
	pointer-events: none;
	transition: 350ms linear;

	&__box {
		display: block;
		background: $color-white;
		@include size(90%, auto);
		max-height: 80svh;
		position: relative;
		padding: var(--gutter);
		opacity: 0;
		transform: translateY(100px);
		transition: 350ms linear;
		border-radius: calc(var(--radius) / 2);

		&__close {
			position: absolute;
			top: var(--thirdgutter);
			right: var(--thirdgutter);
			display: block;
			@include size(var(--gutter), var(--gutter));
			@extend %arrowClose;
			position: relative;

			&:before,
			&:after {
				background: $color-black;
				@include size(60%, 2px);
			}
		}

		&__content {
			display: block;
			width: 100%;
			text-align: center;
			color: $color-van;
			margin-bottom: var(--gutter);

			&__title, &__text {
				display: block;
				width: 100%;
				text-align: center;
			}

			&__title {
				margin: var(--gutter) 0;
				@extend %text-25;
				font-weight: 600;
			}

			&__image {
				display: block;
				margin: 0 auto;
			}

			&__text {
				@extend %text-20;
				margin-bottom: var(--gutter);
			}

			input {
				@include size(100%, var(--input));
				border: 1px solid $color-black;
				border-radius: var(--inputradius);
			}
		}

		&__btn {
			margin-bottom: var(--minigutter);

			&:last-of-type {
				margin-bottom: 0;
			}
		}
	}

	&__close {
		position: absolute;
		top: 0;
		right: 0;
		margin: var(--minigutter) var(--minigutter) 0 0;

		&:after {
			display: inline-block;
			content: "\00d7";
			font-size: 30px;
			color: $color-black;
		}
	}

	&__error {
		display: block;
		width: 100%;
		color: $color-error;
		@extend %text-14;
		font-weight: 500;
	}

	&__media {
		display: block;
		width: 80%;
		position: relative;
		margin: var(--minigutter) auto var(--gutter);

		.qrlabel {
			position: absolute;
			top: 50%;
			left: 50%;
			display: block;
			transform: translate(-50%, -50%);
		}

		&__image {
			display: block;
			@include size(100%, 100%);
			object-fit: contain;
		}
	}

	&.show {
		opacity: 1;
		pointer-events: all;

		.sharecard__box {
			opacity: 1;
			transform: translateY(0);
		}
	}
}
</style>
