<template>
	<div id="app" class="app">
		<span class="app__bg">
			<img src="@/assets/images/bg.png" alt=""/>
		</span>
		<Icons/>
		<router-view/>
	</div>
</template>

<script>
import {EventBus} from '@/events_bus.js';

import Icons from '@/views/layouts/utils/Icons';

export default {
	name: 'App',
	components: {
		Icons,
	},
	data() {
		return {
			windowWidth: 0,
		}
	},
	methods: {
		checkParams() {
			let params = window.location.search;
			let value = new URLSearchParams(params);
		},
		onResize() {
			let mode = localStorage.getItem('mode');
			this.windowWidth = window.innerWidth;
			if (this.windowWidth < 1200) {
				localStorage.setItem('mode', 'mobile');
				if (mode != 'mobile') {
					window.location.reload();
				}
			} else {
				localStorage.setItem('mode', 'desktop');
				if (mode != 'desktop') {
					window.location.reload();
				}
			}
		},
		init() {
			// this.$api.refreshToken().then(res => {
			// 	console.log('Refresh Token');
			// }).catch(err => {
			// 	console.error('ERR', err);
			// 	if(localStorage.getItem('tokentimer')) {
			// 		this.$router.push({ name: 'login.page' })
			// 	}
			// })
		},
	},
	mounted() {
		this.init();
		this.onResize();
		this.$nextTick(() => {
			window.addEventListener('resize', () => {
				this.onResize();
			});
		});
		this.checkParams();
	}
}
</script>

<style lang="scss">
.app__bg {
	position: absolute;
	top: 0;
	left: 0;
	display: block;
	width: 100%;

	img {
		display: block;
		width: 100%;
		mix-blend-mode: lighten;
	}
}
</style>
