import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n);

function loadLocaleMessages() {
	const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
	const messages = {};
	locales.keys().forEach(key => {
		const matched = key.match(/([A-Za-z0-9-_]+)\./i);
		if (matched && matched.length > 1) {
			const locale = matched[1];
			messages[locale] = locales(key)
		}
	});
	return messages
}

const numberFormats = {
	'it-IT': {
		currency: {
			style: 'currency', currency: 'EUR', symbol: '&euro;'
		},
		decimal: {
			style: 'decimal'
		},
		percent: {
			style: 'percent'
		}
	},
	'en-US': {
		currency: {
			style: 'currency', currency: 'USD', symbol: '&dollar;'
		},
		decimal: {
			style: 'decimal'
		},
		percent: {
			style: 'percent'
		}
	}
};
const dateTimeFormats = {
	'it-IT': {
		short: {
			day: '2-digit', month: '2-digit', year: 'numeric'
		},
		long: {
			year: 'numeric', month: 'short', day: '2-digit',
			weekday: 'short', hour: 'numeric', minute: 'numeric'
		}
	},
	'en-US': {
		short: {
			day: '2-digit', month: '2-digit', year: 'numeric'
		},
		long: {
			year: 'numeric', month: 'short', day: '2-digit',
			weekday: 'short', hour: 'numeric', minute: 'numeric'
		}
	}
}

const getLocaleByBrowser = () => {
	let lstore = localStorage.getItem('locale');
	if (lstore) {
		return lstore
	}
	let l = navigator.language.trim();
	const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
	let language = "it-IT";
	locales.keys().forEach(key => {
		const matched = key.match(/([A-Za-z0-9-_]+)\./i);
		if (matched && matched.length > 1) {
			if (matched[1].trim().toLowerCase() == l.trim().toLowerCase()) {
				language = l;
			}
		}
	});
	return language;
}

export default new VueI18n({
	locale: getLocaleByBrowser(),
	fallbackLocale: getLocaleByBrowser(),
	silentFallbackWarn: true,
	messages: loadLocaleMessages(),
	numberFormats: numberFormats,
	dateTimeFormats: dateTimeFormats
})
