export default {
	install: function (Vue) {
		let service = {};
		Vue.mixin({
			mounted() {
				/**
				 * Crea i parametri per la lista
				 * @param page
				 * @param sort
				 * @param search
				 * @param search_fields
				 * @returns {string}
				 */
				const buildQueryParams = (params) => {
					let paramData = [];
					if (params) {
						Object.keys(params).map((key) => {
							paramData.push(
								`${key}=${encodeURIComponent(params[key])}`
							);
						});
						return paramData.length ? params.join("&") : ``;
					}
					return ``;
				};

				/**
				 * LOGIN
				 */
				let login = {}
				login.login = (id, email) => {
					return this.$api.post(`/vcards/${id}/login`, {email: email});
				}
				login.verify = (id, claim) => {
					return this.$api.post(`/vcards/${id}/verify`, {claim: claim});
				}
				service.login = login;

				/**
				 * VCARD
				 */
				let vcard = {};
				vcard.get = (id) => {
					return this.$api.get(`/vcards/${id}`);
				}
				vcard.download = (id) => {
					return this.$api.get(`/vcards/${id}/download`);
				}
				vcard.update = (id, card) => {
					return this.$api.put(`/vcards/${id}/update`, card);
				}
				service.vcard = vcard;
			},
		});

		Vue.prototype.$service = service;
	},
};
