<template>
	<div id="public" class="public">
		<router-view/>
	</div>
</template>

<script>
export default {
	name: 'PublicLayout',
};
</script>

<style lang="scss">
</style>
