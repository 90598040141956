<template>
	<div class="reqacc" :class="{ 'show': openRequest }">
		<div class="reqacc__box">
				<span class="reqacc__box__content">
					<span class="reqacc__box__content__title">
						Richiesta di modifica
					</span>
					<template v-if="!requestSent">
						<span class="reqacc__box__content__text">
							Per modificare questa Digital Business Card, invieremo una mail al seguente indirizzo {{
								owner
							}}
						</span>
						<!-- <span class="reqacc__box__content__text">
							Inserisci la tua mail per modificare questa Digital Business Card
						</span>
						<form id="reqacc__form" class="reqacc__form form" @submit="sendRequest">
							<label for="email">
								<input type="email" id="email" class="form__input" v-model="form.email" />
								<small v-if="emailerror" class="form__error">
									Il formato della mail non è corretto.
								</small>
							</label>
							<small v-if="errmsg" class="reqacc__error">
								{{ errmsg }}
							</small>
						</form> -->
					</template>
					<template v-else>
						Richiesta inviata controlla la mail
					</template>
				</span>
			<template v-if="!requestSent">
				<span class="reqacc__box__btn btn filled" @click="sendRequest">
					Invia
				</span>
				<span class="reqacc__box__btn btn outline" @click="closeRequest">
					Indietro
				</span>
			</template>
			<template v-else>
				<span class="reqacc__box__btn btn outline" @click="closeRequest">
					Chiudi
				</span>
			</template>
		</div>
	</div>
</template>

<script>
import {EventBus} from '@/events_bus.js';

export default {
	name: 'RequestAccess',
	props: {
		owner: {
			type: String,
			required: true,
		}
	},
	data() {
		return {
			code: null,
			openRequest: false,
			form: {
				email: null,
			},
			emailerror: false,
			errmsg: '',
			requestSent: false,
		}
	},
	methods: {
		validEmail(email) {
			var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			return re.test(email);
		},
		closeRequest() {
			EventBus.$emit('close-request');
			this.openRequest = false;
		},
		sendRequest() {
			if (this.owner) {
				let isValid = this.validEmail(this.owner);
				if (isValid) {
					this.emailerror = false;
					this.$service.login.login(this.code, this.owner).then(res => {
						console.log('RES', res);
						this.requestSent = true;
					}).catch(err => {
						if (err) {
							switch (err.status) {
								case 403:
									this.errmsg = 'La mail inserita non è corretta';
									break;
							}
						}
					})
				} else {
					this.emailerror = true;
				}
			}
		},
		init() {
			if (this.$route && this.$route.query && this.$route.query.id) {
				this.code = this.$route.query.id;
				this.openRequest = true;
			}
		}
	},
	mounted() {
		this.init();
	}
}
</script>

<style lang="scss">
.reqacc {
	position: fixed;
	top: 0;
	left: 0;
	background: rgba($color-black, 0.9);
	@extend %flexCenterCenter;
	@include size(100%, 100svh);
	z-index: 100000;
	opacity: 0;
	pointer-events: none;
	transition: 350ms linear;

	&__box {
		display: block;
		background: $color-white;
		@include size(90%, auto);
		max-height: 80svh;
		position: relative;
		padding: var(--gutter);
		opacity: 0;
		transform: translateY(100px);
		transition: 350ms linear;
		border-radius: calc(var(--radius) / 2);

		&__content {
			display: block;
			width: 100%;
			text-align: center;
			color: $color-van;
			margin-bottom: var(--gutter);

			&__title, &__text {
				display: block;
				width: 100%;
				text-align: center;
				margin-bottom: var(--gutter);
			}

			&__title {
				@extend %text-38;
				font-weight: 600;
			}

			&__text {
				@extend %text-20;
			}

			input {
				@include size(100%, var(--input));
				border: 1px solid $color-black;
				border-radius: var(--inputradius);
			}
		}

		&__btn {
			margin-bottom: var(--minigutter);

			&:last-of-type {
				margin-bottom: 0;
			}
		}
	}

	&__error {
		display: block;
		width: 100%;
		color: $color-error;
		@extend %text-14;
		font-weight: 500;
	}

	&.show {
		opacity: 1;
		pointer-events: all;

		.reqacc__box {
			opacity: 1;
			transform: translateY(0);
		}
	}
}
</style>
