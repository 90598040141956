<template>
	<main id="confirmed" class="confirmed page">
		<span class="confirmed__icon">
			<svg viewBox="0 0 50 50">
				<use xlink:href="#icon--check" width="100%" height="100%"></use>
			</svg>
		</span>
		<section class="confirmed__intro">
			<span class="confirmed__intro__title">
				Fatto!
			</span>
			<span class="confirmed__intro__text">
				Email inviata
			</span>
		</section>
		<section class="confirmed__text">
			Abbiamo inviato la mail di conferma a<br/><br/>
			<strong>{{ email }}</strong><br/><br/>
			Controlla la tua mail e clicca sul magic link che troverai al suo interno per iniziare a modificare o
			migliorare la tua Digital Business Card!
		</section>
	</main>
</template>

<script>
export default {
	name: 'Confirmed',
	data() {
		return {
			email: null,
		}
	},
	methods: {
		init() {
			if (this.$route && this.$route.query && this.$route.query.email) {
				this.email = this.$route.query.email;
			}
		}
	},
	mounted() {
		this.init();
	}
}
</script>

<style lang="scss">
@import '~@/assets/scss/pages/confirmed';
</style>
