<template>
	<main id="main" class="main page">
		<template v-if="showContent">
			<template v-if="isLoading">
				<span class="main__spinner">
					<Spinner/>
				</span>
			</template>
			<template v-else>
				<template v-if="isFree">
					<Landing :code="code"/>
				</template>
				<template v-else>
					<VCard :info="card" :code="code"/>
				</template>
			</template>
		</template>
		<RequestAccess v-if="requestAccess" :owner="owner"/>
		<ShareCard v-if="shareCard" :code="code"/>
	</main>
</template>

<script>
import {EventBus} from '@/events_bus.js';

import Landing from '@/views/components/Landing';
import VCard from '@/views/components/VCard';
import RequestAccess from '@/views/components/RequestAccess';
import ShareCard from '@/views/components/ShareCard';
import Spinner from '@/views/components/common/Spinner';

export default {
	name: 'Main',
	components: {
		Spinner,
		Landing,
		VCard,
		RequestAccess,
		ShareCard
	},
	data() {
		return {
			owner: null,
			showContent: null,
			code: null,
			isFree: true,
			card: null,
			requestAccess: false,
			shareCard: false,
			qr: null,
			isLoading: true,
		}
	},
	methods: {
		init() {
			if (this.$route && this.$route.query && this.$route.query.id) {
				this.code = this.$route.query.id;
				this.$service.vcard.get(this.code).then(res => {
					console.log('RES----', res);
					this.owner = res.owner;
					if (res) {
						this.showContent = true;
						setTimeout(() => {
							this.isLoading = false;
							if (res.data) {
								this.card = res;
								this.isFree = false;
							} else {
								this.isFree = true;
							}
						}, 600)
					}
				})
			}
		}
	},
	mounted() {
		this.init();

		EventBus.$on('request-access', () => {
			this.requestAccess = true;
		})
		EventBus.$on('share-card', () => {
			this.shareCard = true;
		})

		EventBus.$on('close-request', () => {
			this.requestAccess = false;
		})
		EventBus.$on('close-share', () => {
			this.shareCard = false;
		})
	}
}
</script>

<style lang="scss">
.main__spinner {
	position: fixed;
	top: 50%;
	left: 50%;
	@include size(68px, 68px);
	transform: translate(-50%, -50%);

	.spinner {
		@include size(100%, 100%);
	}
}
</style>
